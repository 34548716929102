import { DecisionReason, Experiment, HackleUser, Variation, VariationId, VariationKey } from "../model/model"
import EvaluationFlowFactory from "./flow/EvaluationFlowFactory"
import Workspace from "../workspace/Workspace"

export class Evaluation {
  variationId: VariationId | undefined
  variationKey: VariationKey
  reason: DecisionReason

  constructor(variationId: VariationId | undefined, variationKey: VariationKey, reason: DecisionReason) {
    this.variationId = variationId
    this.variationKey = variationKey
    this.reason = reason
  }

  static withVariation(variation: Variation, reason: DecisionReason): Evaluation {
    return new Evaluation(variation.id, variation.key, reason)
  }

  static withKey(variationKey: VariationKey, reason: DecisionReason): Evaluation {
    return new Evaluation(undefined, variationKey, reason)
  }

  static of(experiment: Experiment, variationKey: VariationKey, reason: DecisionReason): Evaluation {
    const variation = experiment._getVariationByKeyOrNull(variationKey)
    if (variation) {
      return Evaluation.withVariation(variation, reason)
    } else {
      return Evaluation.withKey(variationKey, reason)
    }
  }
}

export default class Evaluator {
  private evaluationFlowFactory: EvaluationFlowFactory

  constructor(evaluationFlowFactory: EvaluationFlowFactory) {
    this.evaluationFlowFactory = evaluationFlowFactory
  }

  evaluate(workspace: Workspace, experiment: Experiment, user: HackleUser, defaultVariationKey: VariationKey): Evaluation {
    const evaluationFlow = this.evaluationFlowFactory.getFlow(experiment.type)
    return evaluationFlow.evaluate(workspace, experiment, user, defaultVariationKey)
  }
}

