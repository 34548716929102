// import {VariationKey} from "../VariationKey"


import Logger from "../logger"

export type Int = number
export type Long = number
export type List<T> = T[]
export type Double = number
export type UserId = string
export type VariationId = Long
export type VariationKey = string
export type ExperimentId = Long
export type ExperimentKey = Long
export type BucketId = Long
export type EventId = Long
export type EventKey = string
export type SegmentKey = string
export type ContainerId = Long

const log = Logger.log

/**
 * An object that contains the decided variation and the reason for the decision.
 */
export class Decision {
  variation: VariationKey
  reason: DecisionReason

  constructor(variation: VariationKey, reason: DecisionReason) {
    this.variation = variation
    this.reason = reason
  }

  static of(variation: VariationKey, reason: DecisionReason) {
    return new Decision(variation, reason)
  }
}

/**
 * An object that contains the decided flag and the reason for the feature flag decision.
 */
export class FeatureFlagDecision {
  isOn: boolean
  reason: DecisionReason

  constructor(isOn: boolean, reason: DecisionReason) {
    this.isOn = isOn
    this.reason = reason
  }

  static on(reason: DecisionReason): FeatureFlagDecision {
    return new FeatureFlagDecision(true, reason)
  }

  static off(reason: DecisionReason): FeatureFlagDecision {
    return new FeatureFlagDecision(false, reason)
  }
}

/**
 * Describes the reason for the [Variation] decision.
 */
export class DecisionReason {
  /**
   * Indicates that the sdk is not ready to use. e.g. invalid SDK key.
   */
  static SDK_NOT_READY = "SDK_NOT_READY"

  /**
   * Indicates that the variation could not be decided due to an unexpected exception.
   */
  static EXCEPTION = "EXCEPTION"

  /**
   * Indicates that the input value is invalid.
   */
  static INVALID_INPUT = "INVALID_INPUT"

  /**
   * Indicates that no experiment was found for the experiment key provided by the caller.
   */
  static EXPERIMENT_NOT_FOUND = "EXPERIMENT_NOT_FOUND"

  /**
   * Indicates that the experiment is in draft.
   */
  static EXPERIMENT_DRAFT = "EXPERIMENT_DRAFT"

  /**
   * Indicates that the experiment was paused.
   */
  static EXPERIMENT_PAUSED = "EXPERIMENT_PAUSED"

  /**
   * Indicates that the experiment was completed.
   */
  static EXPERIMENT_COMPLETED = "EXPERIMENT_COMPLETED"

  /**
   * Indicates that the user has been overridden as a specific variation.
   */
  static OVERRIDDEN = "OVERRIDDEN"

  /**
   * Indicates that the experiment is running but the user is not allocated to the experiment.
   */
  static TRAFFIC_NOT_ALLOCATED = "TRAFFIC_NOT_ALLOCATED"

  /**
   * Indicates that the experiment is running but the user is not allocated to the mutual exclusion experiment.
   */
  static NOT_IN_MUTUAL_EXCLUSION_EXPERIMENT = "NOT_IN_MUTUAL_EXCLUSION_EXPERIMENT"

  /**
   * Indicates that no found identifier of experiment for the user provided by the caller.
   */
  static IDENTIFIER_NOT_FOUND = "IDENTIFIER_NOT_FOUND"

  /**
   * Indicates that the original decided variation has been dropped.
   */
  static VARIATION_DROPPED = "VARIATION_DROPPED"

  /**
   * Indicates that the user has been allocated to the experiment.
   */
  static TRAFFIC_ALLOCATED = "TRAFFIC_ALLOCATED"

  /**
   * Indicates that the user is not the target of the experiment.
   */
  static NOT_IN_EXPERIMENT_TARGET = "NOT_IN_EXPERIMENT_TARGET"

  /**
   * Indicates that no feature flag was found for the feature key provided by the caller.
   */
  static FEATURE_FLAG_NOT_FOUND = "FEATURE_FLAG_NOT_FOUND"

  /**
   * Indicates that the feature flag is inactive.
   */
  static FEATURE_FLAG_INACTIVE = "FEATURE_FLAG_INACTIVE"

  /**
   * Indicates that the user is matched to the individual target of the feature flag.
   */
  static INDIVIDUAL_TARGET_MATCH = "INDIVIDUAL_TARGET_MATCH"

  /**
   * Indicates that the user is matched to the target rule of the feature flag.
   */
  static TARGET_RULE_MATCH = "TARGET_RULE_MATCH"

  /**
   * Indicates that the user did not match any individual targets or target rules.
   */
  static DEFAULT_RULE = "DEFAULT_RULE"
}

export type ExperimentType = "AB_TEST" | "FEATURE_FLAG"
export type ExperimentStatus = "DRAFT" | "RUNNING" | "PAUSED" | "COMPLETED"

export class Experiment {

  id: ExperimentId
  key: ExperimentKey
  type: ExperimentType
  identifierType: string
  status: ExperimentStatus
  version: Long
  variations: Variation[]
  userOverrides: Map<UserId, VariationId>
  segmentOverrides: TargetRule[]
  targetAudiences: Target[]
  targetRules: TargetRule[]
  defaultRule: TargetAction
  containerId: Long | undefined
  _winnerVariationId: VariationId | undefined

  constructor(
    id: ExperimentId,
    key: ExperimentKey,
    type: ExperimentType,
    identifierType: string,
    status: ExperimentStatus,
    version: Long,
    variations: Variation[],
    userOverrides: Map<UserId, VariationId>,
    segmentOverrides: TargetRule[],
    targetAudiences: Target[],
    targetRules: TargetRule[],
    defaultRule: TargetAction,
    containerId: Long | undefined,
    winnerVariationId: VariationId | undefined
  ) {
    this.id = id
    this.key = key
    this.type = type
    this.identifierType = identifierType
    this.status = status
    this.version = version
    this.variations = variations
    this.userOverrides = userOverrides
    this.segmentOverrides = segmentOverrides
    this.targetAudiences = targetAudiences
    this.targetRules = targetRules
    this.defaultRule = defaultRule
    this.containerId = containerId
    this._winnerVariationId = winnerVariationId
  }

  _winnerVariationOrNull(): Variation | undefined {
    if (this._winnerVariationId) {
      return this._getVariationByIdOrNull(this._winnerVariationId)
    }

    return undefined
  }

  _getVariationByIdOrNull(variationId: VariationId): Variation | undefined {
    return this.variations.find((it) => it.id === variationId)
  }

  _getVariationByKeyOrNull(variationKey: VariationKey): Variation | undefined {
    return this.variations.find((it) => it.key === variationKey)
  }
}

export class Variation {
  id: VariationId
  key: VariationKey
  isDropped: boolean

  constructor(id: VariationId, key: VariationKey, isDropped: boolean) {
    this.id = id
    this.key = key
    this.isDropped = isDropped
  }
}

export class Bucket {
  seed: number
  slotSize: number
  slots: Slot[]

  constructor(seed: number, slotSize: number, slots: Slot[]) {
    this.seed = seed
    this.slotSize = slotSize
    this.slots = slots
  }
}

export class Slot {
  startInclusive: number
  endExclusive: number
  variationId: VariationId

  constructor(startInclusive: number, endExclusive: number, variationId: VariationId) {
    this.startInclusive = startInclusive
    this.endExclusive = endExclusive
    this.variationId = variationId
  }

  contains(slotNumber: number): boolean {
    return this.startInclusive <= slotNumber && slotNumber < this.endExclusive
  }
}

export class EventType {
  id: EventId
  key: EventKey

  constructor(id: EventId, key: EventKey) {
    this.id = id
    this.key = key
  }
}

export interface HackleEvent {
  key: string
  value?: number
  properties?: Properties
}

export interface HackleUser {
  identifiers: Identifiers
  properties: Properties
  hackleProperties: Properties
}

export class IdentifierType {
  static ID = "$id"
  static USER = "$userId"
  static DEVICE = "$deviceId"
}

export interface User {
  id?: string
  userId?: string
  deviceId?: string
  identifiers?: Identifiers
  properties?: Properties
}

export interface Identifiers {
  [key: string]: string
}

export class IdentifiersBuilder {
  identifiers: Identifiers = {}

  addIdentifiers(identifiers: Identifiers): IdentifiersBuilder {
    for (const identifierType in identifiers) {
      this.add(identifierType, identifiers[identifierType])
    }
    return this
  }

  add(type: string, value?: string): IdentifiersBuilder {
    if (value && this._isValid(type, value)) {
      this.identifiers[type] = value
    } else {
      log.warn(`Invalid user identifier [type=${type}, value=${value}]`)
    }
    return this
  }

  _isValid(type: string, value: string): boolean {

    if (!type) {
      return false
    }

    if (typeof type !== "string") {
      return false
    }

    if (type.length > 128) {
      return false
    }

    if (!value) {
      return false
    }

    if (typeof value !== "string") {
      return false
    }

    if (value.length > 512) {
      return false
    }

    return true
  }

  build(): Identifiers {
    return this.identifiers
  }
}

export interface Properties {
  [key: string]: string | boolean | number
}

export class Target {
  conditions: TargetCondition[]

  constructor(conditions: TargetCondition[]) {
    this.conditions = conditions
  }
}

export class TargetCondition {
  key: TargetKey
  match: TargetMatch

  constructor(key: TargetKey, match: TargetMatch) {
    this.key = key
    this.match = match
  }
}

export class TargetKey {
  type: TargetKeyType
  name: string

  constructor(type: TargetKeyType, name: string) {
    this.type = type
    this.name = name
  }
}

export class TargetMatch {
  type: MatchType
  operator: MatchOperator
  valueType: MatchValueType
  values: any[]

  constructor(type: MatchType, operator: MatchOperator, valueType: MatchValueType, values: any[]) {
    this.type = type
    this.operator = operator
    this.valueType = valueType
    this.values = values
  }
}

export class TargetAction {

  type: TargetActionType
  variationId: number | undefined
  bucketId: number | undefined

  constructor(type: TargetActionType, variationId: number | undefined, bucketId: number | undefined) {
    this.type = type
    this.variationId = variationId
    this.bucketId = bucketId
  }
}

export class TargetRule {
  target: Target
  action: TargetAction

  constructor(target: Target, action: TargetAction) {
    this.target = target
    this.action = action
  }
}

export class Segment {
  id: Long
  key: SegmentKey
  type: SegmentType
  targets: Target[]

  constructor(id: Long, key: SegmentKey, type: SegmentType, targets: Target[]) {
    this.id = id
    this.key = key
    this.type = type
    this.targets = targets
  }
}

export class Container {
  id: Long
  bucketId: Long
  groups: ContainerGroup[]

  constructor(id: Long, bucketId: Long, groups: ContainerGroup[]) {
    this.id = id
    this.bucketId = bucketId
    this.groups = groups
  }

  getGroupOrNull(containerGroupId: Long): ContainerGroup | undefined {
    return this.groups.find((it) => it.id === containerGroupId)
  }
}

export class ContainerGroup {
  id: Long
  experiments: Long[]

  constructor(id: Long, experiments: Long[]) {
    this.id = id
    this.experiments = experiments
  }
}

function compareValues(a: number, b: number): number {
  return a - b
}

export class Version {

  readonly coreVersion: CoreVersion
  readonly prerelease: MetaVersion
  readonly build: MetaVersion

  constructor(coreVersion: CoreVersion, prerelease: MetaVersion, build: MetaVersion) {
    this.coreVersion = coreVersion
    this.prerelease = prerelease
    this.build = build
  }

  static regExp = /^(0|[1-9]\d*)(?:\.(0|[1-9]\d*))?(?:\.(0|[1-9]\d*))?(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/

  static tryParse(value: any): Version | undefined {
    const match = Version.regExp.exec(value)
    if (!match) return undefined

    const [_, major, minor = "0", patch = "0", prerelease, build] = match

    const coreVersion = new CoreVersion(
      parseInt(major, 10),
      parseInt(minor, 10),
      parseInt(patch, 10)
    )

    return new Version(
      coreVersion,
      MetaVersion.parse(prerelease),
      MetaVersion.parse(build)
    )
  }

  compareTo(other: Version): number {
    return this.coreVersion.compareTo(other.coreVersion)
      || this.prerelease.compareTo(other.prerelease)
  }

  isEqualTo(other: Version): boolean {
    return this.compareTo(other) === 0
  }

  isGreaterThan(other: Version): boolean {
    return this.compareTo(other) > 0
  }

  isGreaterThanOrEqualTo(other: Version): boolean {
    return this.compareTo(other) >= 0
  }

  isLessThan(other: Version): boolean {
    return this.compareTo(other) < 0
  }

  isLessThanOrEqualTo(other: Version): boolean {
    return this.compareTo(other) <= 0
  }
}

export class CoreVersion {

  readonly major: number
  readonly minor: number
  readonly patch: number

  constructor(major: number, minor: number, patch: number) {
    this.major = major
    this.minor = minor
    this.patch = patch
  }

  compareTo(other: CoreVersion): number {
    return compareValues(this.major, other.major)
      || compareValues(this.minor, other.minor)
      || compareValues(this.patch, other.patch)
  }
}

export class MetaVersion {

  readonly identifiers: string[]

  constructor(identifiers: string[]) {
    this.identifiers = identifiers
  }

  private static EMPTY = new MetaVersion([])

  static parse(text: string | undefined): MetaVersion {
    if (!text) {
      return MetaVersion.EMPTY
    } else {
      return new MetaVersion(text.split("."))
    }
  }

  isEmpty(): boolean {
    return this.identifiers.length === 0
  }

  isNotEmpty(): boolean {
    return !this.isEmpty()
  }

  compareTo(other: MetaVersion): number {
    if (this.isEmpty() && other.isEmpty()) {
      return 0
    }

    if (this.isEmpty() && other.isNotEmpty()) {
      return 1
    }

    if (this.isNotEmpty() && other.isEmpty()) {
      return -1
    }

    return this.compareIdentifiers(other)
  }

  private compareIdentifiers(other: MetaVersion): number {
    const length = Math.min(this.identifiers.length, other.identifiers.length)
    for (let i = 0; i < length; i++) {
      const result = MetaVersion.compareIdentifiers(this.identifiers[i], other.identifiers[i])
      if (result !== 0) {
        return result
      }
    }
    return compareValues(this.identifiers.length, other.identifiers.length)
  }

  private static numericIdentifierRegExp = /^(0|[1-9]\d*)$/

  private static compareIdentifiers(identifier1: string, identifier2: string): number {
    if (MetaVersion.numericIdentifierRegExp.test(identifier1) && MetaVersion.numericIdentifierRegExp.test(identifier2)) {
      return compareValues(+identifier1, +identifier2)
    }

    if (identifier1 === identifier2) {
      return 0
    }

    return identifier1 < identifier2 ? -1 : 1
  }
}

export const MATCH_TYPES = ["MATCH", "NOT_MATCH"] as const
export const MATCH_VALUE_TYPES = ["STRING", "NUMBER", "BOOLEAN", "VERSION"] as const
export const MATCH_OPERATORS = ["IN", "CONTAINS", "STARTS_WITH", "ENDS_WITH", "GT", "GTE", "LT", "LTE"] as const
export const TARGET_ACTION_TYPES = ["VARIATION", "BUCKET"] as const
export const TARGET_KEY_TYPES = ["USER_ID", "USER_PROPERTY", "HACKLE_PROPERTY", "SEGMENT"] as const
export const SEGMENT_TYPES = ["USER_ID", "USER_PROPERTY"] as const

export type MatchType = typeof MATCH_TYPES[number]
export type MatchValueType = typeof MATCH_VALUE_TYPES[number]
export type MatchOperator = typeof MATCH_OPERATORS[number]
export type TargetActionType = typeof TARGET_ACTION_TYPES[number]
export type TargetKeyType = typeof TARGET_KEY_TYPES[number]
export type SegmentType = typeof SEGMENT_TYPES[number]


export class TargetingType {

  static IDENTIFIER = new TargetingType("SEGMENT")
  static PROPERTY = new TargetingType("SEGMENT", "USER_PROPERTY", "HACKLE_PROPERTY")
  static SEGMENT = new TargetingType("USER_ID", "USER_PROPERTY", "HACKLE_PROPERTY")

  private supportedKeyTypes: TargetKeyType[]

  constructor(...supportedKeyTypes: TargetKeyType[]) {
    this.supportedKeyTypes = supportedKeyTypes
  }

  supports(keyType: TargetKeyType): boolean {

    return this.supportedKeyTypes.includes(keyType)
  }
}