import { MatchType, TargetMatch } from "../../model/model"
import { OperatorMatcherFactory } from "./OperatorMatcher"
import { ValueMatcherFactory } from "./ValueMatcher"

export default class ValueOperatorMatcher {

  private valueMatcherFactory: ValueMatcherFactory
  private operatorMatcherFactory: OperatorMatcherFactory

  constructor(valueMatcherFactory: ValueMatcherFactory, operatorMatcherFactory: OperatorMatcherFactory) {
    this.valueMatcherFactory = valueMatcherFactory
    this.operatorMatcherFactory = operatorMatcherFactory
  }

  matches(userValue: any, match: TargetMatch): boolean {
    const valueMatcher = this.valueMatcherFactory.getMatcher(match.valueType)
    const operatorMatcher = this.operatorMatcherFactory.getMatcher(match.operator)
    const isMatched = match.values.some((it) => valueMatcher.matches(operatorMatcher, userValue, it))
    return this._matches(match.type, isMatched)
  }

  _matches(matchType: MatchType, isMatched: boolean): boolean {
    switch (matchType) {
      case "MATCH":
        return isMatched
      case "NOT_MATCH":
        return !isMatched
    }
  }
}

