import EventProcessor from "../../../core/internal/event/EventProcessor"
import Event from "../../../core/internal/event/Event"
import { HackleUser } from "../../../core/internal/model/model"
import IdentifierUtil from "../../../core/internal/util/IdentifierUtil"

export default class EventProcessorImpl implements EventProcessor {

  private delegate: EventProcessor
  private exposureEventDedupDeterminer: ExposureEventDedupDeterminer

  constructor(delegate: EventProcessor, exposureEventDedupDeterminer: ExposureEventDedupDeterminer) {
    this.delegate = delegate
    this.exposureEventDedupDeterminer = exposureEventDedupDeterminer
  }

  process(event: Event): void {
    if (this.exposureEventDedupDeterminer.isDedupTarget(event)) {
      return
    }
    this.delegate.process(event)
  }

  close(): void {
    this.delegate.close()
  }

  start(): Promise<void> {
    return this.delegate.start()
  }
}


export class ExposureEventDedupDeterminer {

  private readonly exposureEventDedupIntervalMillis: number

  private cache = new Map<string, number>()
  private currentUser?: HackleUser

  constructor(exposureEventDedupIntervalMillis: number) {
    this.exposureEventDedupIntervalMillis = exposureEventDedupIntervalMillis
  }

  isDedupTarget(event: Event): boolean {

    if (this.exposureEventDedupIntervalMillis === -1) {
      return false
    }

    if (!Event.isExposure(event)) {
      return false
    }

    if (!IdentifierUtil.isEquals(event.user.identifiers, this.currentUser?.identifiers)) {
      this.currentUser = event.user
      this.cache = new Map()
    }

    const key = [event.experiment.id, event.variationId, event.variationKey, event.decisionReason].join("-")
    const now = new Date().getTime()

    const firstExposureTimeMillis = this.cache.get(key)
    if (firstExposureTimeMillis !== undefined && now - firstExposureTimeMillis <= this.exposureEventDedupIntervalMillis) {
      return true
    }

    this.cache.set(key, now)
    return false
  }
}
