import Bucketer from "../bucket/Bucketer"
import { Bucket, Container, Experiment, HackleUser } from "../../model/model"

export default class ContainerResolver {

  private bucketer: Bucketer

  constructor(bucketer: Bucketer) {
    this.bucketer = bucketer
  }

  isUserInContainerGroup(container: Container, bucket: Bucket, experiment: Experiment, user: HackleUser): boolean {
    const identifier = user.identifiers[experiment.identifierType]
    if (!identifier) {
      return false
    }

    const slot = this.bucketer.bucketing(bucket, identifier)
    if (!slot) {
      return false
    }

    const containerGroup = container.getGroupOrNull(slot.variationId)
    if (!containerGroup) {
      throw new Error(`ContainerGroup[${slot.variationId}]`)
    }

    return containerGroup.experiments.includes(experiment.id)
  }
}
