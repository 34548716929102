import TargetMatcher from "../match/TargetMatcher"
import Workspace from "../../workspace/Workspace"
import { Experiment, HackleUser, TargetRule } from "../../model/model"

export default class TargetRuleDeterminer {
  private targetMatcher: TargetMatcher

  constructor(targetMatcher: TargetMatcher) {
    this.targetMatcher = targetMatcher
  }

  determineTargetRuleOrNull(workspace: Workspace, experiment: Experiment, user: HackleUser): TargetRule | undefined {
    return experiment.targetRules.find((it) => this.targetMatcher.matches(it.target, workspace, user))
  }
}
