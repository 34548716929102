import OperatorMatcher from "./OperatorMatcher"
import { MatchValueType, Version } from "../../model/model"

export default interface ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean
}

class StringMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    if (typeof userValue === "string" && typeof matchValue === "string") {
      return operatorMatcher.stringMatches(userValue, matchValue)
    } else {
      return false
    }
  }
}

class NumberMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    if (typeof userValue === "number" && typeof matchValue === "number") {
      return operatorMatcher.numberMatches(userValue, matchValue)
    } else {
      return false
    }
  }
}

class BooleanMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    if (typeof userValue === "boolean" && typeof matchValue === "boolean") {
      return operatorMatcher.booleanMatches(userValue, matchValue)
    } else {
      return false
    }
  }
}

class VersionMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    const userVersion = Version.tryParse(userValue)
    const matchVersion = Version.tryParse(matchValue)
    if (userVersion && matchVersion) {
      return operatorMatcher.versionMatches(userVersion, matchVersion)
    } else {
      return false
    }
  }
}

export class ValueMatcherFactory {

  private static STRING_MATCHER = new StringMatcher()
  private static NUMBER_MATCHER = new NumberMatcher()
  private static BOOLEAN_MATCHER = new BooleanMatcher()
  private static VERSION_MATCHER = new VersionMatcher()

  getMatcher(valueType: MatchValueType): ValueMatcher {
    switch (valueType) {
      case "STRING":
        return ValueMatcherFactory.STRING_MATCHER
      case "NUMBER":
        return ValueMatcherFactory.NUMBER_MATCHER
      case "BOOLEAN":
        return ValueMatcherFactory.BOOLEAN_MATCHER
      case "VERSION":
        return ValueMatcherFactory.VERSION_MATCHER
    }
  }
}
