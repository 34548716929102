import TargetMatcher from "../match/TargetMatcher"
import ActionResolver from "../action/ActionResolver"
import Workspace from "../../workspace/Workspace"
import { Experiment, HackleUser, Variation } from "../../model/model"

export default class OverrideResolver {

  private targetMatcher: TargetMatcher
  private actionResolver: ActionResolver

  constructor(targetMatcher: TargetMatcher, actionResolver: ActionResolver) {
    this.targetMatcher = targetMatcher
    this.actionResolver = actionResolver
  }

  resolveOrNull(workspace: Workspace, experiment: Experiment, user: HackleUser): Variation | undefined {
    const userOverriddenVariation = this._resolveUserOverride(experiment, user)
    if (userOverriddenVariation) {
      return userOverriddenVariation
    }

    return this._resolveSegmentOverride(workspace, experiment, user)
  }

  _resolveUserOverride(experiment: Experiment, user: HackleUser): Variation | undefined {
    const identifier = user.identifiers[experiment.identifierType]
    if (!identifier) {
      return undefined
    }

    const overriddenVariationId = experiment.userOverrides.get(identifier)
    if (!overriddenVariationId) {
      return undefined
    }

    return experiment._getVariationByIdOrNull(overriddenVariationId)
  }

  _resolveSegmentOverride(workspace: Workspace, experiment: Experiment, user: HackleUser): Variation | undefined {

    const overriddenRule = experiment.segmentOverrides.find((it) => this.targetMatcher.matches(it.target, workspace, user))
    if (!overriddenRule) {
      return undefined
    }

    return this.actionResolver.resolveOrNull(overriddenRule.action, workspace, experiment, user)
  }
}
