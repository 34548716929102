export const logLevels = {
  DISABLE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4
}

export default class Logger {
  private static logLevel: number = logLevels.INFO

  static setLogLevel(logLevel: number = logLevels.INFO) {
    this.logLevel = logLevel
  }

  static log = {
    error: (message: string | Error) => {
      if (Logger.logLevel >= logLevels.ERROR) {
        Logger._log("ERROR", message)
      }
    },
    warn: (message: string) => {
      if (Logger.logLevel >= logLevels.WARN) {
        Logger._log("WARN", message)
      }
    },
    info: (message: string) => {
      if (Logger.logLevel >= logLevels.INFO) {
        Logger._log("INFO", message)
      }
    },
    debug: (message: string) => {
      if (Logger.logLevel >= logLevels.DEBUG) {
        Logger._log("DEBUG", message)
      }
    }
  }

  private static _log(level: string, message: string | Error) {
    let color
    switch (level) {
      case "WARN":
        color = "orange"
        break
      case "ERROR":
        color = "red"
        break
      default:
        color = "#AAAAAA"
    }

    if (message instanceof Error) {
      ConsoleLogger.log(level, color, message.message, message)
      return
    }

    try {
      ConsoleLogger.log(level, color, message)
    } catch (e) {}
  }
}

class ConsoleLogger {
  static log(level: string, color: string, message: string, error?: Error) {
    try {
      console.log(`%c[${level}] Hackle: ${message}`, `color: ${color}`)
    } catch (e) {}
  }
}
