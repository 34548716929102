import { Identifiers } from "../model/model"

export default class IdentifierUtil {

  static isEquals(identifiers: Identifiers, other: Identifiers | undefined): boolean {
    if (other === undefined || other === null) {
      return false
    }

    const identifierTypes = Object.keys(identifiers)

    if (identifierTypes.length !== Object.keys(other).length) {
      return false
    }

    return identifierTypes.every((it) => identifiers[it] === other[it])
  }
}
