import Logger, { logLevels } from "./core/internal/logger"
import HttpRequest from "./hackle/http/index.browser"
import HackleClientImpl, { BrowserHackleClient as HackleClient, PageView } from "./hackle/index.browser"
import {
  BROWSER_BATCH_SIZE,
  BROWSER_FLUSH_INTERVAL,
  BROWSER_MIN_POOL_INTERVAL,
  EVENT_DISPATCH_URL,
  EVENT_DISPATCH_URL_POSTFIX,
  EVENT_DISPATCH_URL_PREFIX,
  SDK_NAME_HEADER,
  SDK_VERSION,
  SDK_VERSION_HEADER,
  WORKSPACE_FETCH_URL_POSTFIX,
  WORKSPACE_FETCH_URL_PREFIX
} from "./config"
import EventDispatcher from "./hackle/event/distpcher/index.browser"
import PollingWorkspaceFetcher from "./core/internal/workspace/PollingWorkspaceFetcher"
import { getUserId, removeUserId, setUserId } from "./hackle/user/index.browser"
import "core-js/features/promise"
import "core-js/features/array"
import { DefaultEventProcessor } from "./core/internal/event/EventProcessor"
import EventProcessorImpl, { ExposureEventDedupDeterminer } from "./hackle/event/processor/index.browser"

const log = Logger.log

let hackleClientCache: HackleClient | null = null

interface Config {
  debug?: boolean
  auto_track_page_view?: boolean
  pollingIntervalMillis?: number
  exposureEventDedupIntervalMillis?: number

  [key: string]: string | boolean | number | undefined
}

interface InternalConfig {
  debug: boolean
  log_disabled: boolean
  auto_track_page_view: boolean
  pollingIntervalMillis: number
  exposureEventDedupIntervalMillis: number

  SDK_NAME_HEADER: string
  SDK_VERSION_HEADER: string
}

const defaultConfig: InternalConfig = {
  debug: false,
  log_disabled: false,
  auto_track_page_view: false,
  pollingIntervalMillis: -1,
  exposureEventDedupIntervalMillis: -1,

  SDK_NAME_HEADER: "javascript-sdk_browser",
  SDK_VERSION_HEADER: SDK_VERSION
}

function createInstance(sdkKey: string, _config?: Config): HackleClient {
  const config: InternalConfig = {
    ...defaultConfig,
    ..._config
  }

  if (config.log_disabled) {
    Logger.setLogLevel(logLevels.DISABLE)
  } else {
    if (config.debug) {
      Logger.setLogLevel(logLevels.DEBUG)
    }
  }

  log.debug("sdkKey : " + sdkKey)
  if (!sdkKey) {
    log.error("SDK Key must not be null")
  }

  if (hackleClientCache) {
    log.debug("use already exists hackleClient")
    return hackleClientCache
  }

  let useBeacon = false

  if (typeof window !== "undefined") {
    // @ts-ignore
    useBeacon = window && window.navigator && window.navigator.sendBeacon && true
    if (useBeacon) {
      log.debug("support sendBeacon API")
    }
  }

  const eventDispatcher = new EventDispatcher(sdkKey, HttpRequest, {
    dispatchUrl: EVENT_DISPATCH_URL,
    beaconDispatchUrl: `${EVENT_DISPATCH_URL_PREFIX}${sdkKey}${EVENT_DISPATCH_URL_POSTFIX}`,
    useBeacon: useBeacon,
    headers: {
      [SDK_NAME_HEADER]: config.SDK_NAME_HEADER as string,
      [SDK_VERSION_HEADER]: config.SDK_VERSION_HEADER as string
    }
  })

  let pollingIntervalMillis = -1
  if (config.pollingIntervalMillis !== undefined && typeof config.pollingIntervalMillis === "number" && config.pollingIntervalMillis > 0) {
    pollingIntervalMillis = Math.max(config.pollingIntervalMillis, BROWSER_MIN_POOL_INTERVAL)
  }

  const workspaceFetcher = new PollingWorkspaceFetcher(sdkKey, HttpRequest, {
    fetchUrl: `${WORKSPACE_FETCH_URL_PREFIX}${sdkKey}${WORKSPACE_FETCH_URL_POSTFIX}`,
    updateInterval: pollingIntervalMillis,
    headers: {
      [SDK_NAME_HEADER]: config.SDK_NAME_HEADER as string,
      [SDK_VERSION_HEADER]: config.SDK_VERSION_HEADER as string
    }
  })

  let dedupIntervalMillis: number = -1
  if (config.exposureEventDedupIntervalMillis !== undefined && typeof config.exposureEventDedupIntervalMillis === "number" && config.exposureEventDedupIntervalMillis !== -1) {
    if (config.exposureEventDedupIntervalMillis < 1000 || config.exposureEventDedupIntervalMillis > 1000 * 60 * 60) {
      log.warn(
        "Exposure event dedup interval is outside allowed range[1_000ms..3_600_000ms]. Setting to default value[no dedup]."
      )
      dedupIntervalMillis = -1
    } else {
      dedupIntervalMillis = config.exposureEventDedupIntervalMillis
    }
  }

  const delegate = new DefaultEventProcessor(eventDispatcher, BROWSER_BATCH_SIZE, BROWSER_FLUSH_INTERVAL)
  const dedupDeterminer = new ExposureEventDedupDeterminer(dedupIntervalMillis)
  const eventProcessor = new EventProcessorImpl(delegate, dedupDeterminer)
  const hackleClient = new HackleClientImpl(workspaceFetcher, eventProcessor)

  hackleClientCache = hackleClient

  if (config.auto_track_page_view) {
    const pageView = () =>
      hackleClient.trackPageView({
        user: {
          id: getUserId()
        }
      })

    hackleClient.onReady(() => {
      pageView()

      function customEvent(type: string): Event {
        if (typeof window.Event === "function") return new Event(type)

        const params = { bubbles: false, cancelable: false, detail: undefined }
        const evt = document.createEvent("CustomEvent")
        evt.initCustomEvent(type, params.bubbles, params.cancelable, params.detail)
        return evt
      }

      try {
        history.pushState = ((f) =>
          function pushState() {
            try {
              // @ts-ignore
              var ret = f.apply(this, arguments)
              window.dispatchEvent(customEvent("locationchange"))
              return ret
            } catch (e) {
              if (e instanceof Error) {
                log.error(e)
              } else {
                try {
                  log.error(e as string)
                } catch (ex) {}
              }
            }
          })(history.pushState)

        history.replaceState = ((f) =>
          function replaceState() {
            try {
              // @ts-ignore
              var ret = f.apply(this, arguments)
              window.dispatchEvent(customEvent("locationchange"))
              return ret
            } catch (e) {
              if (e instanceof Error) {
                log.error(e)
              } else {
                try {
                  log.error(e as string)
                } catch (ex) {}
              }
            }
          })(history.replaceState)

        window.addEventListener("popstate", () => {
          try {
            window.dispatchEvent(customEvent("locationchange"))
          } catch (e) {
            if (e instanceof Error) {
              log.error(e)
            } else {
              try {
                log.error(e as string)
              } catch (ex) {}
            }
          }
        })
        window.addEventListener("locationchange", () => {
          try {
            pageView()
          } catch (e) {
            if (e instanceof Error) {
              log.error(e)
            } else {
              try {
                log.error(e as string)
              } catch (ex) {}
            }
          }
        })
      } catch (e) {
        if (e instanceof Error) {
          log.error(e)
        } else {
          try {
            log.error(e as string)
          } catch (ex) {}
        }
      }
    })
  }

  const flush = () => {
    removeUserId()
    hackleClient.close()
  }

  window.addEventListener("onpagehide" in window ? "pagehide" : "unload", flush)

  return hackleClient
}

export { createInstance }
export { getUserId }
export { setUserId }
export { removeUserId }
export { HackleClient }
export { PageView }
export { Logger }
export { Config }

export * from "./core/internal/model/model"

export default {
  createInstance,
  getUserId,
  setUserId,
  removeUserId,
  Logger
}
