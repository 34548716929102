import { ConditionMatcherFactory } from "./ConditionMatcher"
import { HackleUser, Target, TargetCondition } from "../../model/model"
import Workspace from "../../workspace/Workspace"

export default class TargetMatcher {

  private conditionMatcherFactory: ConditionMatcherFactory

  constructor(conditionMatcherFactory: ConditionMatcherFactory) {
    this.conditionMatcherFactory = conditionMatcherFactory
  }

  matches(target: Target, workspace: Workspace, user: HackleUser): boolean {
    return target.conditions.every((it) => this._matches(it, workspace, user))
  }

  _matches(condition: TargetCondition, workspace: Workspace, user: HackleUser): boolean {
    const conditionMatcher = this.conditionMatcherFactory.getMatcher(condition.key.type)
    return conditionMatcher.matches(condition, workspace, user)
  }
}
