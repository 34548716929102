import Bucketer from "../bucket/Bucketer"
import { Experiment, HackleUser, TargetAction, Variation } from "../../model/model"
import Workspace from "../../workspace/Workspace"

export default class ActionResolver {
  private bucketer: Bucketer

  constructor(bucketer: Bucketer) {
    this.bucketer = bucketer
  }

  resolveOrNull(action: TargetAction, workspace: Workspace, experiment: Experiment, user: HackleUser): Variation | undefined {
    switch (action.type) {
      case "VARIATION":
        return this._resolveVariation(action, experiment)
      case "BUCKET":
        return this._resolveBucket(action, workspace, experiment, user)
    }
  }

  _resolveVariation(action: TargetAction, experiment: Experiment): Variation {
    return experiment._getVariationByIdOrNull(action.variationId!)!
  }

  _resolveBucket(action: TargetAction, workspace: Workspace, experiment: Experiment, user: HackleUser): Variation | undefined {
    const bucket = workspace.getBucketOrNull(action.bucketId!)!

    const identifier = user.identifiers[experiment.identifierType]
    if (!identifier) {
      return undefined
    }

    const allocatedSlot = this.bucketer.bucketing(bucket, identifier)
    if (!allocatedSlot) {
      return undefined
    }

    return experiment._getVariationByIdOrNull(allocatedSlot.variationId)
  }
}

