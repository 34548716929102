import {
  DecisionReason,
  EventType,
  Experiment,
  HackleEvent,
  HackleUser,
  IdentifierType,
  Long,
  VariationId,
  VariationKey
} from "../model/model"
import { ExposureEventDto, TrackEventDto } from "./dto"
import { Evaluation } from "../evaluation/Evaluator"
import PropertyUtil from "../util/PropertyUtil"

export default class Event {
  timestamp: Long
  user: HackleUser

  constructor(timestamp: Long, user: HackleUser) {
    this.timestamp = timestamp
    this.user = user
  }

  static exposure(experiment: Experiment, user: HackleUser, evaluation: Evaluation) {
    return new Exposure(
      new Date().getTime(),
      user,
      experiment,
      evaluation.variationId,
      evaluation.variationKey,
      evaluation.reason
    )
  }

  static track(eventType: EventType, event: HackleEvent, user: HackleUser) {
    return new Track(new Date().getTime(), user, eventType, event)
  }

  static isExposure(event: Event): event is Exposure {
    return (event as Exposure).experiment !== undefined
  }

  static isTrack(event: Event): event is Track {
    return (event as Track).eventType !== undefined
  }
}

export class Exposure extends Event {
  experiment: Experiment
  variationId?: VariationId
  variationKey: VariationKey
  decisionReason: DecisionReason

  constructor(
    timestamp: Long,
    user: HackleUser,
    experiment: Experiment,
    variationId: VariationId | undefined,
    variationKey: VariationKey,
    decisionReason: DecisionReason
  ) {
    super(timestamp, user)
    this.experiment = experiment
    this.variationId = variationId
    this.variationKey = variationKey
    this.decisionReason = decisionReason
  }

  toDto(): ExposureEventDto {
    return {
      timestamp: this.timestamp,

      userId: this.user.identifiers[IdentifierType.ID],
      identifiers: this.user.identifiers,
      userProperties: PropertyUtil.filteredProperties(this.user.properties || {}),
      hackleProperties: PropertyUtil.filteredProperties(this.user.hackleProperties || {}),

      experimentId: this.experiment.id,
      experimentKey: this.experiment.key,
      experimentType: this.experiment.type,
      experimentVersion: this.experiment.version,
      variationId: this.variationId,
      variationKey: this.variationKey,
      decisionReason: this.decisionReason.toString()
    }
  }
}

export class Track extends Event {
  eventType: EventType
  event: HackleEvent

  constructor(timestamp: Long, user: HackleUser, eventType: EventType, event: HackleEvent) {
    super(timestamp, user)
    this.eventType = eventType
    this.event = event
  }

  toDto(): TrackEventDto {
    return {
      timestamp: this.timestamp,

      userId: this.user.identifiers[IdentifierType.ID],
      identifiers: this.user.identifiers,
      userProperties: PropertyUtil.filteredProperties(this.user.properties || {}),
      hackleProperties: PropertyUtil.filteredProperties(this.user.hackleProperties || {}),

      eventTypeId: this.eventType.id,
      eventTypeKey: this.eventType.key,
      value: this.event.value || 0,
      properties: PropertyUtil.filteredProperties(this.event.properties || {})
    }
  }
}
