import { Properties } from "../model/model"

export default class PropertyUtil {
  static validate(properties: Properties): boolean {
    if (properties) {
      if (typeof properties !== "object") {
        return false
      }

      for (const key in properties) {
        if (!this.validProperty(key, properties[key])) {
          return false
        }
      }
    }

    return true
  }

  static filteredProperties(properties: Properties): Properties {
    return Object.keys(properties)
      .filter((it) => this.validProperty(it, properties[it]))
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: this.filteredPropertiesValue(properties[key])
        }),
        {}
      )
  }

  static filteredPropertiesValue(value: any): any {
    if (typeof value === "string") {
      if (value.length > 1024) {
        return value.slice(0, 1024)
      }
    }
    return value
  }

  private static validProperty(key: string, value: any): boolean {
    if (!key) {
      return false
    }

    if (typeof key !== "string") {
      return false
    }

    if (key.length > 128) {
      return false
    }

    if (typeof value === "string") {
      return true
    }

    if (typeof value === "boolean") {
      return true
    }

    if (typeof value === "number") {
      return !isNaN(value) && isFinite(value)
    }

    return false
  }
}
